import PropTypes from "prop-types"
import React from "react"

export default function Ancillary({ email, services, telephone, directors }) {

    let tel_link_number = telephone.replace(/\s/g,'').replace(/0/,'+44');

    return (
        <aside className={"relative overflow-hidden bg-gray-100 ancillary"}>
            <div className={"relative z-10 py-10 lg:py-16 container"}>
                <div className={"grid grid-cols-24 gap-4 md:row-gap-8 lg:row-gap-0"}>
                    <div className={"col-span-24 lg:col-span-8 xl:cols-span-9"}>
                        <p className="md:text-center xl:text-left italic">“Our objective is to fully understand the individual needs of our Client”</p>
                    </div>

                    <div className={"col-span-24 md:col-span-8 lg:col-span-4 lg:col-start-10 xl:col-start-11"}>
                        <h2 className={"mb-3 text-2xl md:text-3xl lg:mb-4"}>Directors</h2>

                        <ul className={"ancillary-services"}>
                            {directors.map(director => (
                                <li className={"mb-1 text-primary ancillary-service"} key={director.name}>{director.name}</li>
                            ))}
                        </ul>
                    </div>

                    <div className={"col-span-24 md:col-span-7 md:col-start-10 lg:col-span-4 lg:col-start-15 xl:col-start-16"}>
                        <h2 className={"mb-3 text-2xl md:text-3xl lg:mb-4"}>Services</h2>
                        <ul className={"ancillary-services"}>
                            {services.map(link => (
                                <li className={"mb-1 text-primary ancillary-service"} key={link.title}>{link.title}</li>
                            ))}
                        </ul>
                    </div>

                    <div className={"col-span-24 md:col-span-7 md:col-start-19 lg:col-span-4 lg:col-start-20 xl:col-start-21"}>
                        <h2 className={"mb-3 text-2xl md:text-3xl lg:mb-4"}>Contact</h2>
                        <ul>
                            <li className={"mb-1"}>
                                <a href={'tel:' + tel_link_number} className={"text-primary"}><strong>{telephone}</strong></a>
                            </li>
                            <li>
                                <a href={'mailto:' + email + '?subject=General Enquiry'} className={"text-primary"}><strong>{email}</strong></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    )
    
}

Ancillary.propTypes = {
    email: PropTypes.string,
    telephone: PropTypes.string
}
