import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

let currentYear = new Date().getFullYear();

const Footer = ({ footerLinks, siteAuthor, siteTitle }) => (
    <footer className={"relative"}>
        <div className={"relative z-10 py-5 container md:py-6 lg:py-10"}>
            <div className={"grid grid-cols-12 gap-4 items-center lg:gap-12"}>
                <div className={"col-span-12 md:col-span-6"}>
                    <p className={"mb-0 border-b border-gray-300 pb-5 text-center text-gray-400 md:text-left md:border-b-0 md:pb-0"}>
                        <small className={"text-xs xl:text-sm"}>Copyright {currentYear} {siteTitle}. All rights reserved. Web Design by <a href="https://enovate.co.uk" target="_blank" rel="noopener noreferrer" className={"inline"}>{siteAuthor}</a></small>
                    </p>
                </div>

                <div className={"col-span-12 md:col-span-6"}>
                    <nav>
                        <ul className={"flex flex-wrap flex-row justify-center md:justify-end lg:justify-start"}>
                            {footerLinks.map(link => (
                                <li key={link.title} className={"text-center"}>
                                    <Link to={link.url} className={"mx-2 mb-1 text-gray-400 text-xs sm:mb-0 md:mr-0 md:ml-5 lg:mr-5 lg:ml-0 xl:text-sm"}>
                                        {link.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </footer>
)

Footer.propTypes = {
    siteAuthor: PropTypes.string,
    siteTitle: PropTypes.string,
}

export default Footer
