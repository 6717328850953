import React from "react";
import BackgroundImage from "gatsby-background-image";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

class Masthead extends React.Component {
    componentDidMount() {
        this.swiper=new Swiper('.masthead-carousel', {
            observer: true,
            loop: false,
            lazy: {
                loadPrevNext: true
            },
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });
    }

    render() {
        let masthead = this.props.data;

        if (masthead && masthead.slides && masthead.slides.length === 1) {
            let masthead_image = masthead.slides[0].image;

            return(
                <div className={"mb-10 container masthead-container"}>
                    <BackgroundImage className={"masthead"} fluid={masthead_image}/>
                </div>
            )
        } else if (masthead && masthead.slides && masthead.slides.length > 1) {
            return(
                <div className="relative mb-10 container masthead-container">
                    <div className="swiper-container masthead masthead-carousel">
                        <div className="swiper-wrapper">
                            { masthead.slides.map((slide, index) => {

                                return(
                                    <div className="swiper-slide" key={index}>
                                        <BackgroundImage className="h-full" style={{opacity: 1}} fluid={slide.image}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="bottom-0 flex justify-center w-full py-3 swiper-pagination"></div>
                </div>
            )
        }
    }
}

export default Masthead;
