import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Masthead from "./masthead"
import Ancillary from "./ancillary"
import Footer from "./footer"

export default function Layout({ children, masthead }) {
    
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    author, email, telephone, title
                    footerLinks {
                        title
                        url
                    }
                    services {
                        title
                    }
                    directors {
                        name
                    }
                }
            }
            caseStudies: allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/content/case-studies/details/*/index.md*"}}, sort: {fields: frontmatter___order}) {
                edges {
                    node {
                        frontmatter {
                            path
                            title
                        }
                    }
                }
            }
        }
    `);

    const renderMasthead = () => {
        if (masthead && masthead.display) return <Masthead data={masthead}/>
    };

    return (
        <>
            <Header email={data.site.siteMetadata.email}
                    siteTitle={data.site.siteMetadata.title}
                    telephone={data.site.siteMetadata.telephone}
                    caseStudies={data.caseStudies}/>

            { renderMasthead() }

            <div className={"relative z-10 mb-16 container content-wrap md:mb-20 lg:mb-32 lg:px-24 xl:mb-40"}>
                <main>{children}</main>
            </div>

            <Ancillary email={data.site.siteMetadata.email}
                       services={data.site.siteMetadata.services}
                       telephone={data.site.siteMetadata.telephone}
                       directors={data.site.siteMetadata.directors}/>

            <Footer footerLinks={data.site.siteMetadata.footerLinks}
                    siteAuthor={data.site.siteMetadata.author}
                    siteTitle={data.site.siteMetadata.title}/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
