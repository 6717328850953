import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

function Header ({ email, siteTitle, telephone, caseStudies }) {

    const [isExpanded, toggleExpansion] = useState(false);

    const case_studies = caseStudies.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        return rObj
    });

    return (
        <header className={"relative z-20 py-5 lg:py-12 header"}>
            <div className={"container"}>
                <nav className={"flex items-center justify-between flex-wrap"}>
                    <div className={"flex items-center justify-between w-full lg:w-auto"}>
                        <h1 className={"logo"}>
                            <Link to="/" title={"Return to homepage"} className={"block overflow-hidden whitespace-no-wrap bg-no-repeat bg-contain w-full h-full"}>{siteTitle}</Link>
                        </h1>

                        <button className={"block lg:hidden hamburger"} onClick={() => toggleExpansion(!isExpanded)}>
                            <div className={"hamburger-box"}>
                                <div className={"hamburger-inner"}></div>
                            </div>
                        </button>
                    </div>

                    <div className={`${ isExpanded ? `block pt-5` : `hidden` } flex flex-col w-full lg:flex lg:flex-col-reverse lg:p-0 lg:w-auto`}>
                        <ul className={"flex flex-col lg:flex-row primary-navigation"}>
                            <li className={"lg:ml-12"}>
                                <Link to={"/"} className={"block relative border-b p-2 font-display lowercase text-lg text-primary lg:border-0 lg:px-0 lg:pt-0 lg:pb-6 lg:text-xl xl:text-2xl"} activeClassName={"active"}>Home</Link>
                            </li>
                            <li className={"lg:ml-12"}>
                                <Link to={"/about"} className={"block relative border-b p-2 font-display lowercase text-lg text-primary lg:border-0 lg:px-0 lg:pt-0 lg:pb-6 lg:text-xl xl:text-2xl"} activeClassName={"active"}>About</Link>
                            </li>
                            <li className={"lg:ml-12"}>
                                <Link to={"/services"} className={"block relative border-b p-2 font-display lowercase text-lg text-primary lg:border-0 lg:px-0 lg:pt-0 lg:pb-6 lg:text-xl xl:text-2xl"} activeClassName={"active"}>Services</Link>
                            </li>
                            <li className={"relative lg:ml-12"}>
                                <Link to={"/case-studies"} className={"block relative border-b p-2 font-display lowercase text-lg text-primary lg:border-0 lg:px-0 lg:pt-0 lg:pb-6 lg:text-xl xl:text-2xl"} activeClassName={"active"}>Case Studies</Link>
                                <ul className={"bg-gray-100 p-4 whitespace-no-wrap"}>

                                    {case_studies.map(({ title, path }) => (
                                        <li className={"mx-2"} key={title}>
                                            <Link to={path} className={"block bg-gray-200 px-3 py-2 hover:bg-primary hover:text-white"}>{title}</Link>
                                        </li>
                                    ))}
                                    
                                </ul>
                            </li>
                            <li className={"lg:ml-12"}>
                                <Link to={"/gallery"} className={"block relative border-b p-2 font-display lowercase text-lg text-primary lg:border-0 lg:px-0 lg:pt-0 lg:pb-6 lg:text-xl xl:text-2xl"} activeClassName={"active"}>Gallery</Link>
                            </li>
                            <li className={"lg:ml-12"}>
                                <Link to={"/contact"} className={"block relative p-2 font-display lowercase text-lg text-primary lg:border-0 lg:px-0 lg:pt-0 lg:pb-6 lg:text-xl xl:text-2xl"} activeClassName={"active"}>Contact</Link>
                            </li>
                        </ul>

                        <ul className={"flex flex-col pt-3 text-center xs:flex-row xs:justify-center lg:justify-end lg:mb-8 lg:pt-0 xl:mb-10"}>
                            <li className={"xs:mx-4 lg:m-0"}>
                                <a href="tel:+441245225577" className={"text-gray-600 lg:text-lg xl-text-xl"}>{telephone}</a>
                            </li>
                            <li className={"xs:mx-4 lg:mr-0 lg:ml-12 xl:ml-16"}>
                                <a href="mailto:info@kenjudgeltd.co.uk" className={"text-gray-600 lg:text-lg xl-text-xl"}>{email}</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

Header.propTypes = {
    email: PropTypes.string,
    siteTitle: PropTypes.string,
    telephone: PropTypes.string
}

export default Header
